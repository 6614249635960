import React from 'react';
import './ToggleComponentStyle.css';

// Import your normal and schematic battery icons
import batteryIcon from '../assets/battery.svg';
import batterySchematicIcon from '../assets/battery-schematic.svg';

const ToggleComponentStyle = ({ onToggleChange, useSchematicStyle }) => {
  return (
    <div className="toggle-container" onClick={onToggleChange}>
      <div className={`toggle-switch ${useSchematicStyle ? 'schematic' : 'component'}`}>
        {/* Always show both icons */}
        <img className="icon-left" src={batteryIcon} alt="component" />
        <img className="icon-right" src={batterySchematicIcon} alt="schematic" />

        {/* White square that slides left or right */}
        <div className="selector" />
      </div>
    </div>
  );
};

export default ToggleComponentStyle;
