// src/components/Level.js
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import QuestionComponent from './QuestionComponent';
import GridComponent from './GridComponent';
import DraggableItem from './DraggableItem';
import CheckButton from './CheckButton';
import Overlay from './Overlay';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { LevelContext } from '../contexts/LevelContext';

import rotateConnections from '../rotateConnections';
import updateBulbStatus from '../updateBulbStatus';
import TipPopup from './TipPopup';
import '../App.css';
import './Level.css';

import { doc, setDoc,getDoc,updateDoc, arrayUnion,increment} from 'firebase/firestore'; // Remove updateDoc
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';

import levels from './Levels.js';
import { isMobile, isTablet } from 'react-device-detect';

// src/components/GridCell.js
import componentDictionary from './componentDictionary'; // Adjust path accordingly
import ToggleComponentStyle from './ToggleComponentStyle'; // Import the new toggle component

const Level = ({ isCustomLevel = false }) => {
  const location = useLocation();
  const levelDataFromLocation = location.state?.levelData;
  const isTestMode = !!levelDataFromLocation;
  const levelContainerStyle = isTestMode ? { border: '4px solid #FFC300' } : {};
  const [showTipPopup, setShowTipPopup] = useState(false);
  const [tipMessage, setTipMessage] = useState('');

  const [hasDraggedComponent, setHasDraggedComponent] = useState(false);
  const [hasRotatedComponent, setHasRotatedComponent] = useState(false);

  const [animateDraggableItem, setAnimateDraggableItem] = useState(false);
const [animateGridCells, setAnimateGridCells] = useState([]);


  console.log('Custom Level:', isCustomLevel);
  const { levelIndex, exerciseIndex,levelId } = useParams();
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);
  const { setLevelCompletion } = useContext(LevelContext);
  const [initialGrid, setInitialGrid] = useState([]);
  const [mappedPrePlacedComponents, setMappedPrePlacedComponents] = useState([]);
  const [mappedDraggableItems, setMappedDraggableItems] = useState([]);


  const { currentUser } = useContext(AuthContext);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentExercise, setCurrentExercise] = useState(null);
  

  const [grid, setGrid] = useState([]);
  const [bulbStatus, setBulbStatus] = useState({
    'red-light-bulb': "OFF",
    'green-light-bulb': "OFF",
    'yellow-light-bulb': "OFF",
  });
  const [starStatus, setStarStatus] = useState(false);
  const [lastRemovedItemId, setLastRemovedItemId] = useState(null);
  const [resetCounter, setResetCounter] = useState(0);
  const [itemCounts, setItemCounts] = useState({});

  const [resultMessage, setResultMessage] = useState('');
  const [errors, setErrors] = useState([]);
  const isTouchDevice = isMobile || isTablet;
  const [reset, setReset] = useState(false); // Add reset state
  const [nextAvailableCell, setNextAvailableCell] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [useSchematicStyle, setUseSchematicStyle] = useState(false); // Add state for toggle
  
  useEffect(() => {
    // If the lesson is not yet loaded, do nothing.
    if (!isInitialized) return;
  
    // If you don't even have an exercise, do nothing.
    if (!currentExercise) return;
  
    // Extract from metadata
    const meta = currentExercise.metadata || {};
    const hideToggle = meta.hideSchematicToggle === true;
  
    // If toggle is hidden, force schematic off (component style)
    if (hideToggle) {
      setUseSchematicStyle(false);
      return; 
    }
  
    // Otherwise, if we have a user, fetch from Firestore
    if (currentUser) {
      const fetchSchematicPreference = async () => {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (
            userData.settings &&
            typeof userData.settings.useSchematicStyle === 'boolean'
          ) {
            setUseSchematicStyle(userData.settings.useSchematicStyle);
          }
        }
      };
      fetchSchematicPreference();
    }
  }, [isInitialized, currentExercise, currentUser]);

  useEffect(() => {
    const fetchLevelData = async () => {
      if (isTestMode) {
        // Use levelDataFromLocation as currentLevel and currentExercise
        setCurrentLevel(levelDataFromLocation);
        setCurrentExercise(levelDataFromLocation);
        setIsInitialized(true);
      } else{
        if (isCustomLevel) {
          const levelRef = doc(db, 'customLevels', levelId);
          const levelDoc = await getDoc(levelRef);
          if (levelDoc.exists()) {
            const levelData = levelDoc.data();
            setCurrentLevel(levelData);
            setCurrentExercise(levelData); // For custom levels, currentLevel is the exercise

            // Increment play count
            await updateDoc(levelRef, {
              playCount: increment(1),
            });
          }
        } else {
          const levelIdx = parseInt(levelIndex, 10);
          const exerciseIdx = parseInt(exerciseIndex, 10);
          const currentLevelData = levels[levelIdx];
          const currentExerciseData = currentLevelData.exercises[exerciseIdx];
          setCurrentLevel({ ...currentLevelData, levelIdx });
          setCurrentExercise({ ...currentExerciseData, exerciseIdx });
        }
      }
      setIsInitialized(true);
    };

    fetchLevelData();
  }, [isCustomLevel, levelId, levelIndex, exerciseIndex,isTestMode, levelDataFromLocation]);


  useEffect(() => {
    if (currentLevel) {
      const {
        prePlacedComponents = [],
        draggableItems = [],
        goal = [],
        metadata,
        tags = [],
        educationalMessages = {},
      } = currentExercise;
      
  
      // Map components
      const mappedPrePlaced = prePlacedComponents.map((component) => ({
        ...component,
        ...componentDictionary[component.type],
      }));
  
      const mappedDraggable = draggableItems.map((item) => ({
        ...item,
        ...componentDictionary[item.type],
        id: item.id,
        itemCount: item.itemCount,
      }));
  
      // Determine grid size
      const gridCol = metadata?.gridCol || 5;
      const gridRow = metadata?.gridRow || 5;

      const initialGridData = Array(gridRow)
        .fill(null)
        .map(() => Array(gridCol).fill(null));
  
      // Initialize grid
      const newGrid = [...initialGridData];
      mappedPrePlaced.forEach((component) => {
        const rotatedConnections = rotateConnections(
          component.connections || {},
          component.rotation || 0
        );
        const newComponent = {
          ...component,
          prePlaced: true,
          connections: rotatedConnections,
        };
        newGrid[component.row][component.col] = newComponent;
      });
      setGrid(newGrid);
      setInitialGrid(initialGridData);
  
      // Initialize item counts
      setItemCounts(
        mappedDraggable.reduce((acc, item) => {
          acc[item.id] = item.itemCount;
          return acc;
        }, {})
      );

    
      

    // Set the tip message if available
    if (currentExercise.tip) {
      setTipMessage(currentExercise.tip.message);
      setShowTipPopup(true);

      // Handle tip actions
      const { actions } = currentExercise.tip;

      if (actions) {
        // Animate draggable items if 'drag' action is true and user hasn't dragged yet
        if (actions.drag && !hasDraggedComponent) {
          setAnimateDraggableItem(true);
        } else {
          setAnimateDraggableItem(false);
        }

        setAnimateGridCells((prevAnimateGridCells) => {
          let newAnimateGridCells = [...prevAnimateGridCells];

          // Handle rotation actions
          if (actions.rotation && !hasRotatedComponent) {
            const rotationCells = actions.rotation.map(cell => ({ ...cell, animationType: 'rotate' }));
            newAnimateGridCells = newAnimateGridCells.concat(rotationCells);
          } else {
            // Remove rotation animations if hasRotatedComponent is true
            newAnimateGridCells = newAnimateGridCells.filter(
              cell => cell.animationType !== 'rotate'
            );
          }

          // Handle tap actions
          if (actions.tap) {
            const tapCells = actions.tap.map(cell => ({ ...cell, animationType: 'growGlow' }));
            // Add tap cells if they are not already in the array
            tapCells.forEach(tapCell => {
              if (!newAnimateGridCells.some(cell => cell.rowIndex === tapCell.rowIndex && cell.colIndex === tapCell.colIndex && cell.animationType === 'growGlow')) {
                newAnimateGridCells.push(tapCell);
              }
            });
          }

          return newAnimateGridCells;
        });
      } else {
        setAnimateDraggableItem(false);
        setAnimateGridCells([]);
      }
    } else {
      setShowTipPopup(false);
      setAnimateDraggableItem(false);
      setAnimateGridCells([]);
    }
      // Store mapped components
      setMappedPrePlacedComponents(mappedPrePlaced);
      setMappedDraggableItems(mappedDraggable);
      setIsInitialized(true);
    }
  }, [currentExercise]);
  

  useEffect(() => {
    if (isInitialized){
    updateBulbStatus(grid, setBulbStatus,setStarStatus);
    console.log('Bulb status update:', bulbStatus);
  }
  }, [grid]);


useEffect(() => {
  if (isInitialized){
  setNextAvailableCell(findNextAvailableCell());
  }
}, [grid]);

  const refreshPage = () => {
    window.location.reload();
  };

  const handleTipClose = () => {
    setShowTipPopup(false);
  };
  

  const levelIdx = isCustomLevel ? null : parseInt(levelIndex, 10);// Parse the level index from the URL
  const exerciseIdx = isCustomLevel ? null : parseInt(exerciseIndex, 10);// Parse the exercise index from the URL
  
  console.log('levelIndex:', levelIdx, 'exerciseIndex:', exerciseIdx);
  console.log('Levels array:', levels); // Log the levels array

  //const currentLevel = levels[levelIdx];
  // const currentExercise = currentLevel.exercises[exerciseIdx];

  // const { prePlacedComponents = [], draggableItems = [], goal = [], metadata, tags = [], educationalMessages = {} } = currentExercise;

  if (!currentLevel) {
    return <div>Loading...</div>;
  }


  const {
    prePlacedComponents = [],
    draggableItems = [],
    goal = [],
    metadata,
    tags = [],
    educationalMessages = {},
  } = currentExercise;
  const shouldHideToggle = metadata.hideSchematicToggle === true;

  
  const updateGrid = (row, col, item) => {
    if (grid[row][col] && grid[row][col].prePlaced) {
      return;
    }
    //console.log(`Updating grid at row: ${row}, col: ${col} with item: ${JSON.stringify(item)}`);
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col) {
          //console.log(`Placing item at row: ${rowIndex}, col: ${colIndex}`);
          return { ...item, rotation: item.rotation, id: item.id, useSchematicStyle }; // Ensure the item has its ID and style
        }
        return c;
      })
    );

    setGrid(newGrid);
    //console.log("Updated grid state:", newGrid);
  };

  const rotateItem = (row, col) => {
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col && c) {
          const newRotation = (c.rotation + 90) % 360;
          const newConnections = rotateConnections(c.connections, 90);
          return { ...c, rotation: newRotation, connections: newConnections, useSchematicStyle };
        }
        return c;
      })
    );
  // Remove the cell from animateGridCells where animationType is 'rotate'
  setAnimateGridCells(prevCells =>
    prevCells.filter(
      cell => !(cell.rowIndex === row && cell.colIndex === col && cell.animationType === 'rotate')
    )
  );

  if (!hasRotatedComponent) {
    setHasRotatedComponent(true);
  }

  setGrid(newGrid);
};

  const removeItem = (row, col) => {
    const item = grid[row][col];
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col) {
          return null;
        }
        return c;
      })
    );
    setGrid(newGrid);
  if (item) {
    setLastRemovedItemId(item.id);
    setResetCounter(prev => prev + 1);

    setItemCounts(prev => {
      const newCount = { ...prev };
      newCount[item.id] += 1;
      //console.log(`COUNT increase (removed) ${item.type} (${item.id}): ${newCount[item.id]} remaining`);
      return newCount;
    });
  }
};


const handleCheck = () => {
  setShowOverlay(true);
};

const handleContinue = () => {
  setShowOverlay(false);
  if (isTestMode) {
    navigate('/level-creator'); // Navigate back to the level creator
  } else {
    handleNextExercise();
  }
};

const handleTryAgain = () => {
  setShowOverlay(false);
};



const handleLevelComplete = async (starAchieved) => {
  if (isTestMode) {
    // Do not update user progress in test mode
    return;
  }

  if (!isCustomLevel) {
    // Existing logic for predefined levels
    setLevelCompletion((prevState) => {
      const newState = [...prevState];

      // Ensure exercises array exists for the current level
      newState[levelIdx].exercises = newState[levelIdx].exercises || [];

      // Mark current exercise as complete with star status
      newState[levelIdx].exercises[exerciseIdx] = {
        completed: true,
        starAchieved: starStatus, // or use starAchieved if passed as a parameter
      };

      // Check if all exercises in the current level are complete
      const allExercisesCompleted = currentLevel.exercises.every(
        (_, idx) => newState[levelIdx].exercises[idx]?.completed
      );

      if (allExercisesCompleted) {
        newState[levelIdx].completed = true;
        newState[levelIdx].starAchieved = currentLevel.exercises.every(
          (_, idx) => newState[levelIdx].exercises[idx]?.starAchieved
        );
      }
      return newState;
    });
  }

  if (currentUser) {
    const userRef = doc(db, 'users', currentUser.uid);

    try {
      // Get the user's current progress
      const userDoc = await getDoc(userRef);
      let userData = userDoc.exists() ? userDoc.data() : {};
      let userProgress = userData.progress || {};

      let pointsEarned = 0; // Initialize points earned

      if (!isCustomLevel) {
        // Logic for predefined levels

        // Get the current exercise's progress (if exists)
        const currentExerciseProgress =
          userProgress[levelIdx]?.[exerciseIdx] || {
            completed: false,
            starAchieved: false,
          };

        // Calculate points to be added (only add points if there's an improvement)
        if (!currentExerciseProgress.completed) {
          // First-time completion
          pointsEarned += 10; // Base points for completing the exercise
        }

        if (!currentExerciseProgress.starAchieved && starAchieved) {
          // Earning a star for the first time
          pointsEarned += 5; // Additional points for earning a star
        }

        // If there's an improvement, update Firestore
        if (pointsEarned > 0) {
          // Update the progress for the current exercise
          const updatedProgress = {
            ...userProgress,
            [levelIdx]: {
              ...(userProgress[levelIdx] || {}),
              [exerciseIdx]: {
                completed: true,
                starAchieved: starAchieved, // Update with the new star status
                timestamp: new Date(),
              },
            },
          };

          // Update Firestore with the new progress and increment points
          await updateDoc(userRef, {
            progress: updatedProgress,
            points: increment(pointsEarned),
          });

          console.log(`Points earned: ${pointsEarned}`);
        } else {
          console.log(
            'No points earned (exercise already completed or star already achieved).'
          );
        }
      } else {
        // Logic for custom levels

        // Get the array of completed custom levels
        let customLevelsCompleted = userData.customLevelsCompleted || [];

        if (!customLevelsCompleted.includes(levelId)) {
          // First-time completion of this custom level
          customLevelsCompleted.push(levelId);
          pointsEarned += 15; // Award points for completing a custom level

          // Update Firestore to include the new completed custom level
          await updateDoc(userRef, {
            customLevelsCompleted: customLevelsCompleted,
            points: increment(pointsEarned),
          });

          console.log(`Custom level completed, points awarded: ${pointsEarned}`);
        } else {
          console.log('Custom level already completed.');
        }
      }
    } catch (error) {
      console.error('Error updating user progress:', error.message);
    }
  }
};

  



  // Logic to handle the transition to the next exercise or level
  const handleNextExercise = () => {
    if (!isCustomLevel) {
      const nextExerciseIndex = exerciseIdx + 1;
      if (nextExerciseIndex < currentLevel.exercises.length) {
        navigate(`/level/${levelIndex}/exercise/${nextExerciseIndex}`);
      } else {
        navigate('/learn');
      }
    } else {
      navigate('/explore'); // Navigate back to explore page for custom levels
    }
  };
  

const findNextAvailableCell = () => {
  for (let rowIndex = 0; rowIndex < grid.length; rowIndex++) {
    for (let colIndex = 0; colIndex < grid[rowIndex].length; colIndex++) {
      if (!grid[rowIndex][colIndex]) {
        return { row: rowIndex, col: colIndex };
      }
    }
  }
  return null;
};

// Call this whenever grid updates to update the next available cell


const placeInNextAvailableCell = (item) => {
  if (nextAvailableCell) {
    const rotatedConnections = rotateConnections(item.connections, item.rotation);

    updateGrid(nextAvailableCell.row, nextAvailableCell.col, { ...item, rotation: item.rotation, connections: rotatedConnections });

    // Decrease the item count
    setItemCounts(prevCounts => {
      const newCounts = { ...prevCounts };
      newCounts[item.id] = prevCounts[item.id] - 1; // Decrease the count by 1
      return newCounts;
    });

    setNextAvailableCell(findNextAvailableCell()); // Update the next available cell after placing the item
  }
};

const resetLevel = () => {
  // Reset the grid to the initial state
  const newGrid = [...initialGrid];
  mappedPrePlacedComponents.forEach((component) => {
    const rotatedConnections = rotateConnections(
      component.connections || {},
      component.rotation || 0
    );
    const newComponent = {
      ...component,
      prePlaced: true,
      connections: rotatedConnections,
    };
    newGrid[component.row][component.col] = newComponent;
  });
  setGrid(newGrid);

  // Reset other state variables
  setBulbStatus({
    'red-light-bulb': 'OFF',
    'green-light-bulb': 'OFF',
    'yellow-light-bulb': 'OFF',
  });
  setStarStatus(false);
  setLastRemovedItemId(null);
  setResetCounter(0);
  setItemCounts(
    mappedDraggableItems.reduce((acc, item) => {
      acc[item.id] = item.itemCount;
      return acc;
    }, {})
  );
  setResultMessage('');
  setErrors([]);
  setReset((prev) => !prev);
};

const renderProgressBar = () => {
  if (!isCustomLevel && currentLevel.exercises && currentLevel.exercises.length > 1) {
    return (
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{ width: `${((exerciseIdx + 1) / currentLevel.exercises.length) * 100}%` }}
        ></div>
      </div>
    );
  } else {
    // For custom levels or levels with a single exercise
    return (
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: '100%' }}></div>
      </div>
    );
  }
};

const handleExit = () => {
  if (isTestMode) {
    navigate('/level-creator');
  } else if (levelId) {
    navigate('/explore');
  } else {
    navigate('/learn');
  }
};

const handleDragStart = () => {
  if (!hasDraggedComponent) {
    setHasDraggedComponent(true);
    setAnimateDraggableItem(false); // Stop animating draggable items
  }
};

const shouldAnimateCell = (row, col) => {
  return animateGridCells.some(
    (cell) => cell.rowIndex === row && cell.colIndex === col
  );
};

const getCellAnimationType = (row, col) => {
  const cell = animateGridCells.find(
    (cell) => cell.rowIndex === row && cell.colIndex === col
  );
  return cell ? cell.animationType : null;
};

const handleCellTap = (row, col) => {
  // Remove the cell from animateGridCells where animationType is 'growGlow'
  setAnimateGridCells(prevCells =>
    prevCells.filter(
      cell => !(cell.rowIndex === row && cell.colIndex === col && cell.animationType === 'growGlow')
    )
  );
};

const handleToggleChange = async () => {
  const newValue = !useSchematicStyle;
  setUseSchematicStyle(newValue);

  if (currentUser) {
    const userRef = doc(db, 'users', currentUser.uid);
    await updateDoc(userRef, {
      'settings.useSchematicStyle': newValue,
    });
  }
};


  return (
    <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend} options={{ enableMouseEvents: true }}>
      <div className="app" style={levelContainerStyle}>
        <div>
        <div className="top-container">
          <button className="exit-button" onClick={resetLevel}>
            <i className="fas fa-undo" style={{fontSize:'24px'}}></i>
          </button>

          {/* Progress Bar */}
          {renderProgressBar()}

        <button className="exit-button" onClick={handleExit}>
          <i className="fas fa-times"></i>
        </button>

        </div>

        {/* QuestionComponent directly under top-container */}
        <div className="question-component">
          <QuestionComponent goal={goal} starStatus={starStatus} useSchematicStyle={useSchematicStyle} />
        </div>
        </div>
        <div>
        <GridComponent 
        grid={grid} 
        setGrid={setGrid} 
        updateGrid={updateGrid} 
        removeItem={removeItem} 
        rotateItem={rotateItem} 
        bulbStatus={bulbStatus} 
        starStatus={starStatus} 
        setItemCounts={setItemCounts} 
        nextAvailableCell={nextAvailableCell} 
        isLevelCreator={false}
        getCellAnimationType={getCellAnimationType}
        handleCellTap={handleCellTap}
        useSchematicStyle={useSchematicStyle} // Pass the toggle state to GridComponent
        />
        {!shouldHideToggle && (
            <ToggleComponentStyle
              onToggleChange={handleToggleChange}
              useSchematicStyle={useSchematicStyle}
            />
          )}
      </div>
        

        <div className="bottom-container">
          <div className="draggable-items">
            {mappedDraggableItems.map((item, index) => (
              <DraggableItem key={index} item={item} reset={lastRemovedItemId === item.id && resetCounter} remainingCount={itemCounts[item.id]} placeInNextAvailableCell={placeInNextAvailableCell}
              onDragStart={handleDragStart} 
              animate={!hasDraggedComponent && index === 0 && animateDraggableItem}
              useSchematicStyle={useSchematicStyle} // Pass the toggle state to DraggableItem
               />
            ))}
          </div>
          <CheckButton onCheck={handleCheck} />
        </div>

        {showOverlay && (
          <Overlay
            resultMessage={resultMessage}
            onContinue={handleContinue}
            onTryAgain={handleTryAgain}
            isStarConnected={starStatus}
            goal={goal}
            bulbStatus={bulbStatus}
            exerciseTags={tags} 
            educationalMessages={educationalMessages}
            errors={errors} // Ensure errors is always an array
            onLevelComplete={handleLevelComplete}
          />
        )}

              {/* Render TipPopup */}
      {/* {showTipPopup && (
        <TipPopup message={tipMessage} onClose={handleTipClose} />
      )} */}
      </div>
    </DndProvider>
  );

};

export default Level;
