import React from 'react';
import GridCell from './GridCell';
import './GridComponent.css';

const GridComponent = ({ grid, setGrid, updateGrid, removeItem, rotateItem, bulbStatus,starStatus,setItemCounts,nextAvailableCell,isLevelCreator,handleEditResistor,getCellAnimationType,handleCellTap,useSchematicStyle}) => {

  return (
    <div className="grid">
      {grid.map((row, rowIndex) => (
        <div className="row" key={rowIndex}>
          {row.map((cell, cellIndex) => (
            <GridCell
              key={cellIndex}
              row={rowIndex}
              col={cellIndex}
              item={cell}
              grid={grid} // Pass grid as prop
              setGrid={setGrid} // Pass setGrid as prop
              updateGrid={updateGrid}
              rotateItem={rotateItem}
              removeItem={removeItem}
              bulbStatus={bulbStatus}
              starStatus={starStatus}
              setItemCounts={setItemCounts}
              isNextAvailable={nextAvailableCell && nextAvailableCell.row === rowIndex && nextAvailableCell.col === cellIndex}
              isLevelCreator={isLevelCreator}
              handleEditResistor={handleEditResistor}
              animationType={getCellAnimationType(rowIndex, cellIndex)}
              handleCellTap={handleCellTap}
              useSchematicStyle={useSchematicStyle}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default GridComponent;
