import React from 'react';
import './QuestionComponent.css';

import redLightBulbOn from '../assets/red-light-bulb-on.svg';
import redLightBulbDim from '../assets/red-light-bulb-dim.svg';
import redLightBulbOff from '../assets/red-light-bulb-off.svg';

import yellowLightBulbOn from '../assets/yellow-light-bulb-on.svg';
import yellowLightBulbDim from '../assets/yellow-light-bulb-dim.svg';
import yellowLightBulbOff from '../assets/yellow-light-bulb-off.svg';

import greenLightBulbOn from '../assets/green-light-bulb-on.svg';
import greenLightBulbDim from '../assets/green-light-bulb-dim.svg';
import greenLightBulbOff from '../assets/green-light-bulb-off.svg';

import lightBulbOff from '../assets/light-bulb-off.svg';
import lightBulbOffSchematic from '../assets/light-bulb-off-schematic.svg';

// Schematic assets (if you have them)
import redLightBulbOnSchematic from '../assets/red-light-bulb-on-schematic.svg';
import redLightBulbDimSchematic from '../assets/red-light-bulb-dim-schematic.svg';
import redLightBulbOffSchematic from '../assets/red-light-bulb-off-schematic.svg';

import yellowLightBulbOnSchematic from '../assets/yellow-light-bulb-on-schematic.svg';
import yellowLightBulbDimSchematic from '../assets/yellow-light-bulb-dim-schematic.svg';
import yellowLightBulbOffSchematic from '../assets/yellow-light-bulb-off-schematic.svg';

import greenLightBulbOnSchematic from '../assets/green-light-bulb-on-schematic.svg';
import greenLightBulbDimSchematic from '../assets/green-light-bulb-dim-schematic.svg';
import greenLightBulbOffSchematic from '../assets/green-light-bulb-off-schematic.svg';


const getBulbImage = (type, status, useSchematicStyle) => {
  // Only show something if the bulb’s status is ON or DIM
  if (!['ON', 'DIM'].includes(status)) return null;

  // Example fallback function: if we try to get a schematic that doesn't exist,
  // we can revert to the normal version
  const fallback = (schematic, normal) => (useSchematicStyle && schematic ? schematic : normal);

  switch (type) {
    case 'red-light-bulb':
      if (status === 'ON') {
        return fallback(redLightBulbOnSchematic, redLightBulbOn);
      } else if (status === 'DIM') {
        return fallback(redLightBulbDimSchematic, redLightBulbDim);
      }
      return fallback(redLightBulbOffSchematic, redLightBulbOff); // If you ever display OFF

    case 'yellow-light-bulb':
      if (status === 'ON') {
        return fallback(yellowLightBulbOnSchematic, yellowLightBulbOn);
      } else if (status === 'DIM') {
        return fallback(yellowLightBulbDimSchematic, yellowLightBulbDim);
      }
      return fallback(yellowLightBulbOffSchematic, yellowLightBulbOff);

    case 'green-light-bulb':
      if (status === 'ON') {
        return fallback(greenLightBulbOnSchematic, greenLightBulbOn);
      } else if (status === 'DIM') {
        return fallback(greenLightBulbDimSchematic, greenLightBulbDim);
      }
      return fallback(greenLightBulbOffSchematic, greenLightBulbOff);

    default:
      return null;
  }
};

const getBackgroundColor = (type) => {
  switch (type) {
    case 'red-light-bulb':
      return '#F0C6C6'; // Light red
    case 'yellow-light-bulb':
      return '#F7E9B7'; // Light yellow
    case 'green-light-bulb':
      return '#DCEBCB'; // Light green
    default:
      return '#f5f5f5'; // Grey for undefined items
  }
};

const QuestionComponent = ({ goal,useSchematicStyle  }) => {
  const referenceOffIcon = useSchematicStyle ? lightBulbOffSchematic : lightBulbOff;
  return (
    <div className="question">
      <h3>
        Create a circuit to match <span className="no-wrap">the bulbs (
        <img src={referenceOffIcon} alt="light-bulb-off" className="small-bulb-icon" />
        ) below:</span>
      </h3>
      <div className="bulb-indicators">
        {/* Only display bulbs that are "ON" or "DIM" */}
        {goal.filter(bulb => bulb.status === "ON" || bulb.status === "DIM").map((bulb, index) => (
          <div
            key={index}
            className="bulb-container-goal"
            style={{ backgroundColor: getBackgroundColor(bulb.type) }}
          >
            <img
              className="bulb-goal"
              src={getBulbImage(bulb.type, bulb.status, useSchematicStyle)}
              alt={`${bulb.type}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionComponent;
