import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import Header from './Header';
import Footer from './Footer';
import './LeaderboardPage.css';

import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const LeaderboardPage = () => {
  const [users, setUsers] = useState([]);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(usersRef, orderBy('points', 'desc'));
        const querySnapshot = await getDocs(q);
        const usersList = [];
        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          // Only include users with a defined and non-empty name
          if (userData.name && userData.name.trim() !== '') {
            usersList.push({ id: doc.id, ...userData });
          }
        });
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, []);

  const getPositionColor = (index) => {
    switch (index) {
      case 0:
        return '#FFC300'; // Gold
      case 1:
        return '#C2C2C2'; // Silver
      case 2:
        return '#CC782E'; // Bronze
      default:
        return '#1FB6FF'; // Blue
    }
  };

  return (
    <>
      <Header />
      <div className="main-section">
          <div className="title-container">
            <h3>Leaderboard</h3>
            <p>Compete with others around the world!</p>
          </div>

        <div className="leaderboard-list">
          {users.map((user, index) => {
            const isCurrentUser = currentUser && currentUser.uid === user.id;
            return (
              <div
                key={user.id}
                className={`leaderboard-item ${isCurrentUser ? 'current-user' : ''}`}
              >
              <div className="position-circle">
                <span className="position-number">{index + 1}</span>
              </div>
              <span className="user-name">{user.name || user.email}</span>
              <span className="user-points">{user.points || 0}</span>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LeaderboardPage;
