import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './GridCell.css';
import rotateConnections from '../rotateConnections';

import redLightBulbOn from '../assets/red-light-bulb-on.svg';
import redLightBulbOff from '../assets/red-light-bulb-off.svg';
import redLightBulbDim from '../assets/red-light-bulb-dim.svg';
import redLightBulbExploded from '../assets/red-light-bulb-exploded.svg';
import redLightBulbOnSchematic from '../assets/red-light-bulb-on-schematic.svg';
import redLightBulbDimSchematic from '../assets/red-light-bulb-dim-schematic.svg';
import redLightBulbOffSchematic from '../assets/red-light-bulb-off-schematic.svg';
import redLightBulbExplodedSchematic from '../assets/red-light-bulb-exploded-schematic.svg';


import yellowLightBulbOn from '../assets/yellow-light-bulb-on.svg';
import yellowLightBulbOff from '../assets/yellow-light-bulb-off.svg';
import yellowLightBulbDim from '../assets/yellow-light-bulb-dim.svg';
import yellowLightBulbExploded from '../assets/yellow-light-bulb-exploded.svg';
import yellowLightBulbOnSchematic from '../assets/yellow-light-bulb-on-schematic.svg';
import yellowLightBulbDimSchematic from '../assets/yellow-light-bulb-dim-schematic.svg';
import yellowLightBulbOffSchematic from '../assets/yellow-light-bulb-off-schematic.svg';
import yellowLightBulbExplodedSchematic from '../assets/yellow-light-bulb-exploded-schematic.svg';


import greenLightBulbOn from '../assets/green-light-bulb-on.svg';
import greenLightBulbOff from '../assets/green-light-bulb-off.svg';
import greenLightBulbDim from '../assets/green-light-bulb-dim.svg';
import greenLightBulbExploded from '../assets/green-light-bulb-exploded.svg';
import greenLightBulbOnSchematic from '../assets/green-light-bulb-on-schematic.svg';
import greenLightBulbDimSchematic from '../assets/green-light-bulb-dim-schematic.svg';
import greenLightBulbOffSchematic from '../assets/green-light-bulb-off-schematic.svg';
import greenLightBulbExplodedSchematic from '../assets/green-light-bulb-exploded-schematic.svg';


import starOff from '../assets/star-off.svg';
import starOn from '../assets/star-on.svg';

import switchOnImage from '../assets/comp-switch-on.svg';
import switchOffImage from '../assets/comp-switch-off.svg';
import switchOnImageSchematic from '../assets/comp-switch-on-schematic.svg';
import switchOffImageSchematic from '../assets/comp-switch-off-schematic.svg';



import componentDictionary from './componentDictionary'; // Adjust path accordingly


const getBulbImage = (type, bulbStatus,useSchematicStyle) => {
  const statusMap = bulbStatus[type] || 'OFF'; // e.g., 'ON', 'DIM', 'OFF', 'EXPLODED'

  const normalRed = {
    ON: redLightBulbOn,
    DIM: redLightBulbDim,
    EXPLODED: redLightBulbExploded,
    OFF: redLightBulbOff,
  };

  const schematicRed = {
    ON: redLightBulbOnSchematic || redLightBulbOn,
    DIM: redLightBulbDimSchematic || redLightBulbDim,
    EXPLODED: redLightBulbExplodedSchematic || redLightBulbExploded,
    OFF: redLightBulbOffSchematic || redLightBulbOff,
  };

  const normalyellow = {
    ON: yellowLightBulbOn,
    DIM: yellowLightBulbDim,
    EXPLODED: yellowLightBulbExploded,
    OFF: yellowLightBulbOff,
  };

  const schematicyellow = {
    ON: yellowLightBulbOnSchematic || yellowLightBulbOn,
    DIM: yellowLightBulbDimSchematic || yellowLightBulbDim,
    EXPLODED: yellowLightBulbExplodedSchematic || yellowLightBulbExploded,
    OFF: yellowLightBulbOffSchematic || yellowLightBulbOff,
  };

  const normalgreen = {
    ON: greenLightBulbOn,
    DIM: greenLightBulbDim,
    EXPLODED: greenLightBulbExploded,
    OFF: greenLightBulbOff,
  };

  const schematicgreen = {
    ON: greenLightBulbOnSchematic || greenLightBulbOn,
    DIM: greenLightBulbDimSchematic || greenLightBulbDim,
    EXPLODED: greenLightBulbExplodedSchematic || greenLightBulbExploded,
    OFF: greenLightBulbOffSchematic || greenLightBulbOff,
  };

  switch (type) {
    case 'red-light-bulb':
      return useSchematicStyle ? schematicRed[statusMap] : normalRed[statusMap];
    case 'yellow-light-bulb':
      return useSchematicStyle ? schematicyellow[statusMap] : normalyellow[statusMap];
    case 'green-light-bulb':
      return useSchematicStyle ? schematicgreen[statusMap] : normalgreen[statusMap];
    default: // If it's not a recognized bulb type, fallback or return null
      return null;
  }
};


//   switch (type) {
//     case 'red-light-bulb':
//       if (bulbStatus['red-light-bulb'] === 'ON') return redLightBulbOn;
//       if (bulbStatus['red-light-bulb'] === 'DIM') return redLightBulbDim; // You need a dim image asset
//       if (bulbStatus['red-light-bulb'] === 'EXPLODED') return redLightBulbExploded; // You need an exploded image asset
//       return redLightBulbOff;

//     case 'yellow-light-bulb':
//       if (bulbStatus['yellow-light-bulb'] === 'ON') return yellowLightBulbOn;
//       if (bulbStatus['yellow-light-bulb'] === 'DIM') return yellowLightBulbDim;
//       if (bulbStatus['yellow-light-bulb'] === 'EXPLODED') return yellowLightBulbExploded;
//       return yellowLightBulbOff;

//     case 'green-light-bulb':
//       if (bulbStatus['green-light-bulb'] === 'ON') return greenLightBulbOn;
//       if (bulbStatus['green-light-bulb'] === 'DIM') return greenLightBulbDim;
//       if (bulbStatus['green-light-bulb'] === 'EXPLODED') return greenLightBulbExploded;
//       return greenLightBulbOff;

//     default:
//       return null;
//   }
// };


const getStarImage = (starStatus) => {
  console.log("StarStatus", starStatus);
  return starStatus ? starOn : starOff;
};

const GridCell = ({  row, col, item, grid, setGrid, updateGrid, removeItem, rotateItem, bulbStatus,starStatus,setItemCounts = null,isNextAvailable,isLevelCreator = false, handleEditResistor,animationType,handleCellTap,useSchematicStyle  }) => {
  const [isEditingResistor, setIsEditingResistor] = useState(false);
  const [resistanceInputValue, setResistanceInputValue] = useState(item?.resistanceValue || 330);

  const animationClass = item && animationType ? `grid-cell-animate-${animationType}` : '';

  const handleEditButtonClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    handleEditResistor({ ...item, row, col }, 'grid');
  };

  const [{ isOver }, drop] = useDrop({
    accept: ['ITEM', 'CATALOG_ITEM'],
    drop: (draggedItem) => {
      if (item) {
        // Check if something already exists.
        return { rejected: true };
      }
      console.log(`Item dropped: ${JSON.stringify(draggedItem)} at row: ${row}, col: ${col}`);

    // Rotate the connections based on the item's rotation
    const initialConnections = componentDictionary[draggedItem.type].connections;
    const rotatedConnections = rotateConnections(initialConnections, draggedItem.rotation || 0);

    if (isLevelCreator) {
      // Level Creator specific logic
      if (draggedItem.source === 'catalog' || draggedItem.source === 'inventory') {
        const newItem = { ...draggedItem, connections: rotatedConnections };
        const source = draggedItem.source === 'catalog' ? 'fromCatalog' : 'fromInventory';
        updateGrid(row, col, newItem, source);
      } else if (draggedItem.source === 'grid') {
        updateGridAndRemoveOldItem(row, col, { ...draggedItem }, draggedItem.row, draggedItem.col);
      } else {
        return { rejected: true };
      }
    } else {
        // Update the grid with the new item and remove the old item
        updateGridAndRemoveOldItem(
          row,
          col,
          { ...draggedItem, connections: rotatedConnections },
          draggedItem.row,
          draggedItem.col
        );
    }
      return { rejected: false };
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ITEM',
    item: () => {
      // Ensure row and col are correctly set in the item
      const dragItem = { ...item, row, col,rotation: item.rotation !== undefined ? item.rotation : 0 };
      if (isLevelCreator) {
        dragItem.source = 'grid';
      }
      return dragItem;
    },
    canDrag: () => isLevelCreator || !item?.prePlaced,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (draggedItem, monitor) => {
      if (!monitor.didDrop()) {
        console.log("Drag unsuccessful", draggedItem.row, draggedItem.col);
        removeItem(draggedItem.row, draggedItem.col);
      }

    },
  });


  const getBackgroundColor = (item, isLevelCreator) => {
    
    if (!item) {
      return undefined;
    }
    switch (item.type) {
      case 'red-light-bulb':
        return '#F0C6C6'; // Light red
      case 'yellow-light-bulb':
        return '#F7E9B7'; // Light yellow
      case 'green-light-bulb':
        return '#DCEBCB'; // Light green
      default:
        if (item.prePlaced || isLevelCreator) {
          return '#f5f5f5'; // Grey for pre-placed items or in Level Creator
        }
        return undefined;
    }
  };

  const updateGridAndRemoveOldItem = (newRow, newCol, newItem, oldRow, oldCol) => {
    let item = newItem
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === newRow && colIndex === newCol) {
          //console.log(`Placing item at row: ${newRow}, col: ${newCol}`);

          if (setItemCounts) {
            setItemCounts((prev) => {
              const newCount = { ...prev };
              newCount[item.id] -= 1;
              return newCount;
            });
          }

          return { ...newItem, rotation: newItem.rotation !== undefined ? newItem.rotation : 0 };
        }
        if (rowIndex === oldRow && colIndex === oldCol) {
          //console.log(`Removing item from row: ${oldRow}, col: ${oldCol}`);
          if (setItemCounts) {
            setItemCounts((prev) => {
              const newCount = { ...prev };
              newCount[item.id] += 1;
              return newCount;
            });
          }

          return null;
        }
        return c;
      })
    );
    setGrid(newGrid);
    console.log("Updated grid state:", newGrid);
  };


  const handleSwitchToggle = () => {
    const newSwitchState = !item.isOn; // Toggle switch state
    const newConnections = newSwitchState
      ? componentDictionary['switch'].onConnections
      : componentDictionary['switch'].offConnections;

    // Decide which svg paths go with ON vs OFF
    // (So the item’s own .svgPath matches its new state)
    const newSvgPath = newSwitchState ? switchOnImage : switchOffImage;
    const newSchematicSvgPath = newSwitchState
      ? switchOnImageSchematic
      : switchOffImageSchematic;

    // Update the grid with the new connections for the switch
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col) {
          return { ...item, connections: newConnections, isOn: newSwitchState,
            svgPath: newSvgPath,
            schematicSvgPath: newSchematicSvgPath,
           }; // Apply new connections and state
        }
        return c;
      })
    );

    setGrid(newGrid); // Set the updated grid state
  };


  const handleTouchEnd = (e) => {
    e.preventDefault();
    if (item) {
      if (item.type === 'switch') {
        handleSwitchToggle();
      }
      else {
        if(!item.prePlaced){
          rotateItem(row, col);
        }
      }
    }

    if (animationType === 'growGlow') {
      // Call the handler to stop the animation
      handleCellTap(row, col);
    }
  };

  // Adjusted onClick handler
  const handleClick = () => {
    if (item) {
      if (item.type === 'switch') {
        handleSwitchToggle();
      } else {
        if (!item.prePlaced) {
          rotateItem(row, col);
        }
      }
    }

    if (animationType === 'growGlow') {
      // Call the handler to stop the animation
      handleCellTap(row, col);
    }
    
  };

  // Dynamically set the style of the resistor label based on the rotation
  const getResistorLabelStyle = (rotation) => {
    switch (rotation) {
      case 0:
        // Position at the bottom center
        return {
          bottom: '0px',
          left: '50%',
          transform: 'translateX(-50%)',
        };
  
      case 90:
        // Position underneath the resistor with -90deg rotation for correct alignment
        return {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-90deg)',
          backgroundColor: 'white', // White background for visibility
          padding: '2px 2px',
          borderRadius: '3px',
        };
  
      case 180:
        // Position at the top center, similar to case 0 but at the top
        return {
          top: '0px', // Positioned at the top
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)', // No rotation needed
        };
  
      case 270:
        // Similar to 90 degrees but rotated -90 degrees to realign the text
        return {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(90deg)',
          backgroundColor: 'white', // White background for visibility
          padding: '2px 2px',
          borderRadius: '3px',
        };
  
      default:
        return {};
    }
  };
  
  const getItemImageSrc = () => {
    if (!item) return null;

    // Only if it’s a switch, we pick between normal vs schematic paths:
    if (item?.type === 'switch') {
      // If schematic mode is ON, show item.schematicSvgPath; else show item.svgPath
      return useSchematicStyle && item.schematicSvgPath
        ? item.schematicSvgPath
        : item.svgPath;
    }
  // If it's a light bulb, choose based on ON/DIM/EXPLODED, plus schematic or normal
  if (item.type.includes('light-bulb')) {
    return getBulbImage(item.type, bulbStatus, useSchematicStyle);
  }


    // If the component dictionary gave us `schematicSvgPath`, use it
    if (useSchematicStyle && item.schematicSvgPath) {
      return item.schematicSvgPath;
    }

    // Otherwise, default
    return item.svgPath;
  };

  return (
    <div
    className={`grid-cell ${animationClass} ${item && item.prePlaced ? 'pre-placed' : ''} ${isOver ? 'hovered' : ''}`}
    ref={drop}
    onClick={handleClick}
    onTouchEnd={handleTouchEnd}
    style={{ backgroundColor: getBackgroundColor(item, isLevelCreator) }}
    >
        {/* Add the next-available indicator inside the grid-cell */}
      {isNextAvailable && <div className="next-available"></div>}
      {item && (
        <div className="grid-item" ref={drag} style={{ transform: `rotate(${item?.rotation ?? 0}deg)`, opacity: isDragging ? 0.5 : 1,transition: 'transform 0.2s ease','--initial-rotation': `${item?.rotation ?? 0}deg`, }}>
        <img src={getItemImageSrc()} alt={item.type} />
        
          {/* Resistor Specific Rendering */}
          {item.type === 'resistor' && (
            <>
      {item && item.type === 'resistor' && (
        <>
          {/* Resistor Value Display */}
          <div className="resistance-value-grid" style={getResistorLabelStyle(item.rotation)}>
            {item.resistanceValue}Ω
          </div>
          {/* Edit Icon */}
            {isLevelCreator && (
              <div
                className="edit-icon"
                onClick={handleEditButtonClick}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEditButtonClick(e);
                }}
              >
                <i className="fas fa-pen"></i>
              </div>
            )}
        </>
      )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GridCell;